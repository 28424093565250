// =========================================================================
// Typography
// =========================================================================

h1,
h2,
h3,
h4 {
  font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.65625rem;
}

h1,
.h1 {
  font-size: 2.25rem; // 36px
}

h2,
.h2 {
  font-size: 1.875rem; // 30px
}

h3,
.h3 {
  font-size: 1.5rem; // 24px
}

h4,
.h4 {
  font-size: 1.125rem; // 18px
}

h5,
.h5 {
  font-size: 0.875rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-bottom: 1.25rem;
  // margin-bottom: .65rem; // ~10px
  line-height: 1.7; // ~10px

  &.fs-1-25 {
    line-height: 1.5;
  }
  &.fs-1-5 {
    line-height: 1.4;
  }
  &.fs-1-75 {
    line-height: 1.3;
  }
  &.fs-2 {
    line-height: 1.1;
  }
}

a {
  opacity: 1;
  transition: opacity 0.25s ease;

  &[class^='text-']:hover,
  &[class*='text-']:hover {
    opacity: 0.9;
  }
}
