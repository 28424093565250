// =========================================================================
// Functional CSS
// - these helper classes complement the Bootstrap 4 default ones that can
//   can be found in the boootrstrap/utilites directory
// =========================================================================

// Margin & Padding Helper Generator
// - use by adding the class `p-t-0-25` to get paddin-top of 0.25rem
@each $class,
  $property
    in (
      ('p-t', 'padding-top'),
      ('p-b', 'padding-bottom'),
      ('p-r', 'padding-right'),
      ('p-l', 'padding-left'),
      ('m-t', 'margin-top'),
      ('m-b', 'margin-bottom'),
      ('m-r', 'margin-right'),
      ('m-l', 'margin-left'),
      ('p', 'padding'),
      ('m', 'margin')
    )
{
  .#{$class}-0 {
    #{$property}: 0 !important;
  }
  .#{$class}-0-25 {
    #{$property}: 0.25rem !important;
  }
  .#{$class}-0-5 {
    #{$property}: 0.5rem !important;
  }
  .#{$class}-0-75 {
    #{$property}: 0.75rem !important;
  }
  .#{$class}-1 {
    #{$property}: 1rem !important;
  }
  .#{$class}-1-25 {
    #{$property}: 1.25rem !important;
  }
  .#{$class}-1-5 {
    #{$property}: 1.5rem !important;
  }
  .#{$class}-1-75 {
    #{$property}: 1.75rem !important;
  }
  .#{$class}-2 {
    #{$property}: 2rem !important;
  }
  .#{$class}-2-25 {
    #{$property}: 2.25rem !important;
  }
  .#{$class}-2-5 {
    #{$property}: 2.5rem !important;
  }
  .#{$class}-2-75 {
    #{$property}: 2.75rem !important;
  }
  .#{$class}-3 {
    #{$property}: 3rem !important;
  }
  .#{$class}-3-25 {
    #{$property}: 3.25rem !important;
  }
  .#{$class}-3-5 {
    #{$property}: 3.5rem !important;
  }
  .#{$class}-3-75 {
    #{$property}: 3.75rem !important;
  }
  .#{$class}-4 {
    #{$property}: 4rem !important;
  }
  .#{$class}-4-25 {
    #{$property}: 4.25rem !important;
  }
  .#{$class}-4-5 {
    #{$property}: 4.5rem !important;
  }
  .#{$class}-4-75 {
    #{$property}: 4.75rem !important;
  }
  .#{$class}-5 {
    #{$property}: 5rem !important;
  }
}

// Font Size Helpers Generator
// - use by adding the class `fs-0-5` to get font-size of 0.5rem
@each $class,
  $size
    in (
      ('0', '0'),
      ('0-5', '0.5rem'),
      ('0-6', '0.6rem'),
      ('0-7', '0.7rem'),
      ('0-8', '0.8rem'),
      ('0-9', '0.9rem'),
      ('1', '1rem'),
      ('1-25', '1.25rem'),
      ('1-5', '1.5rem'),
      ('1-75', '1.75rem'),
      ('2', '2rem'),
      ('2-25', '2.25rem'),
      ('2-5', '2.5rem'),
      ('2-75', '2.75rem'),
      ('3', '3rem'),
      ('3-25', '3.25rem'),
      ('3-5', '3.5rem'),
      ('3-75', '3.75rem'),
      ('4', '4rem'),
      ('4-25', '4.25rem'),
      ('4-5', '4.5rem'),
      ('4-75', '4.75rem'),
      ('5', '5rem')
    )
{
  .fs-#{$class} {
    font-size: #{$size} !important;
  }
}

// Line Height Helpers
// - use by adding the class `lh-1` to get line-height of 1
.lh-normal {
  line-height: normal;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-1-1 {
  line-height: 1.1 !important;
}
.lh-1-2 {
  line-height: 1.2 !important;
}
.lh-1-3 {
  line-height: 1.3 !important;
}
.lh-1-4 {
  line-height: 1.4 !important;
}
.lh-1-5 {
  line-height: 1.5 !important;
}
.lh-1-6 {
  line-height: 1.6 !important;
}
.lh-1-7 {
  line-height: 1.7 !important;
}
.lh-1-8 {
  line-height: 1.8 !important;
}
.lh-1-9 {
  line-height: 1.9 !important;
}
.lh-2 {
  line-height: 2 !important;
}
.lh-3 {
  line-height: 3 !important;
}
.lh-4 {
  line-height: 4 !important;
}
.lh-5 {
  line-height: 5 !important;
}

// Color Swatches Generators
// - use by adding the class `b-white` to get background-color of white
@each $class, $property in (('background', 'background-color'), ('text', 'color'), ('b', 'border-color')) {
  .#{$class}-white {
    #{$property}: $white !important;
  }
  .#{$class}-primary-darkest {
    #{$property}: $adscore-primary-darkest !important;
  }
  .#{$class}-primary-darker {
    #{$property}: $adscore-primary-darker !important;
  }
  .#{$class}-primary-dark {
    #{$property}: $adscore-primary-dark !important;
  }
  .#{$class}-primary {
    #{$property}: $adscore-primary !important;
  }
  .#{$class}-primary-light {
    #{$property}: $adscore-primary-light !important;
  }
  .#{$class}-primary-lighter {
    #{$property}: $adscore-primary-lighter !important;
  }
  .#{$class}-primary-lightest {
    #{$property}: $adscore-primary-lightest !important;
  }
  .#{$class}-secondary-darkest {
    #{$property}: $adscore-secondary-darkest !important;
  }
  .#{$class}-secondary-darker {
    #{$property}: $adscore-secondary-darker !important;
  }
  .#{$class}-secondary-dark {
    #{$property}: $adscore-secondary-dark !important;
  }
  .#{$class}-secondary {
    #{$property}: $adscore-secondary !important;
  }
  .#{$class}-secondary-light {
    #{$property}: $adscore-secondary-light !important;
  }
  .#{$class}-secondary-lighter {
    #{$property}: $adscore-secondary-lighter !important;
  }
  .#{$class}-secondary-lightest {
    #{$property}: $adscore-secondary-lightest !important;
  }
  .#{$class}-neutral-darkest {
    #{$property}: $adscore-neutral-darkest !important;
  }
  .#{$class}-neutral-darker {
    #{$property}: $adscore-neutral-darker !important;
  }
  .#{$class}-neutral-dark {
    #{$property}: $adscore-neutral-dark !important;
  }
  .#{$class}-neutral {
    #{$property}: $adscore-neutral !important;
  }
  .#{$class}-neutral-light {
    #{$property}: $adscore-neutral-light !important;
  }
  .#{$class}-neutral-lighter {
    #{$property}: $adscore-neutral-lighter !important;
  }
  .#{$class}-neutral-lightest {
    #{$property}: $adscore-neutral-lightest !important;
  }
  .#{$class}-good {
    #{$property}: $adscore-good !important;
  }
  .#{$class}-warning {
    #{$property}: $adscore-warning !important;
  }
}

// Text Weight Helpers
.text-regular {
  font-weight: 400 !important;
}
.text-bold {
  font-weight: 700 !important;
}
.text-heavy {
  font-weight: 800 !important;
}
.text-ultra {
  font-weight: 900 !important;
}

// Text Alignment Helpers
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

// Border Helpers
// - to get a secondary-light colored border-top use classes `b-t b-secaondary-light`
.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0;
}

.b-r {
  border-right-width: 1px;
}
.b-l {
  border-left-width: 1px;
}
.b-t {
  border-top-width: 1px;
}
.b-b {
  border-bottom-width: 1px;
}
.b-a {
  border-width: 1px;
}

.b-dashed {
  border-style: dashed;
}
.b-thick {
  border-width: 2px;
}

.b-transparent {
  border-color: $blackAlpha40;
}
.b-transparent-white {
  border-color: $blackAlpha30;
}

// Opacity Helpers
.o-0 {
  opacity: 0;
}
.o-0-1 {
  opacity: 0.1;
}
.o-0-15 {
  opacity: 0.15;
}
.o-0-2 {
  opacity: 0.2;
}
.o-0-25 {
  opacity: 0.25;
}
.o-0-5 {
  opacity: 0.5;
}
.o-0-75 {
  opacity: 0.75;
}
.o-1 {
  opacity: 1;
}

// cursor classes
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-help {
  cursor: help !important;
}
.cursor-text {
  cursor: text !important;
}
.cursor-crosshair {
  cursor: crosshair !important;
}

//custom-margin-class
@media (min-width: 576px) {
  .m-l-md-1-25 {
    margin-left: 1.25rem;
  }
}
