@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints';

/* ========================================================================
     Component: top-navbar
 ========================================================================== */

//
// Horizontal top navbar
// based on the bootstrap navbar styles and markup
// ----------------------------------------------------
// helper mixin to disable bs gradient mixin
@mixin gradient-remove() {
  background-image: none;
  background-repeat: no-repeat;
  filter: none;
}

$nav-header-wd: $aside-wd;
$nav-header-wd-toggled: $aside-wd-collapsed;

$nav-header-wd-toggled-text: $aside-wd-collapsed-text;

$nav-top-bg: $adscore-primary;
$nav-top-bg-start: $adscore-primary;
$nav-top-bg-end: $adscore-primary;
$nav-header-bg: transparent;

$nav-admin-header-bg: $adscore-admin;

$inactive-color: lighten($adscore-primary, 40%);
$active-color: $white;

$nav-top-item-mobile: $inactive-color;
$nav-top-item-mobile-active: $active-color;

$nav-top-item-desktop: $inactive-color;
$nav-top-item-desktop-active: $active-color;

// Navbar top layout
.topnavbar {
  -webkit-backface-visibility: hidden; /* fixes chrome jump */
  backface-visibility: hidden; /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  padding: 0;
  min-height: $navbar-height;
  background-color: $nav-top-bg;
  color: $inactive-color;

  .admin-panel {
    background-color: $nav-admin-header-bg;
  }

  @include media-breakpoint-up(md) {
    .navbar-header {
      width: $nav-header-wd;
      text-align: center;
      .navbar-brand {
        width: 100%;
      }
    }
  }

  &.demo {
    background-image: url('/assets/img/adscore--demo-dash--header-pattern.svg');
    background-repeat: repeat;
  }
}

// Navbar Mobile General styles
// ------------------------------
.topnavbar {
  position: relative;
  max-width: 100vw;

  .navbar-header {
    background-color: $nav-header-bg;
    @include media-breakpoint-up(md) {
      background-image: none;
    }
  }

  .navbar-header {
    position: relative;
    z-index: 11;
    padding-left: 2rem;

    @include media-breakpoint-up(md) {
      padding-left: 0;
    } // Reset default to allow handle via different logo size
    .navbar-brand {
      padding: 0;

      .demo-badge {
        position: absolute;
        color: $adscore-secondary;
        background-color: $adscore-yellow;
        font-size: 0.6rem;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1;
        padding: 0.25rem 0.25rem 0.2rem 0.33rem;
        border-radius: 3px;
        border: 2px solid $adscore-primary;
        bottom: 0.05rem;
        left: 1.67rem;
      }
    } // Different Logo size depends on the navbar state
    .brand-logo,
    .brand-logo-collapsed {
      > img {
        margin: 0 auto;
      }
    }
    .brand-logo {
      display: block;
      padding: 10px 15px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .brand-logo-collapsed {
      display: none;
      padding: 6px 15px;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }

  .dropdown {
    position: static; // allow header to be parent of dropdown
    .dropdown-menu {
      position: absolute;
      margin-top: 0; // close to header
      top: auto;
      left: 0;
      right: 0;
    }
  } // Top navbar dropdown on desktop
  @include media-breakpoint-up(lg) {
    .dropdown {
      position: relative;
      .dropdown-menu {
        // standard left alignment
        top: $navbar-height - 1;
        left: 0;
        right: auto;
      } // redefined since we don't used navbar-expand-* class
      .dropdown-menu-right {
        right: 0;
        left: auto;
      }
    }
  }
  .navbar-nav > .nav-item > .navbar-text {
    color: $nav-top-item-desktop;
  }

  .navbar-nav > .nav-item > .nav-link {
    padding: 1.438rem 0.95rem;
    font-size: 1rem;
  }
  .navbar-nav > .nav-item > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    color: $nav-top-item-desktop;
    &:hover,
    &:focus {
      color: $nav-top-item-desktop-active;
    }
  } // Navbar link active style
  .navbar-nav > .nav-item.active > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    &,
    &:hover,
    &:focus {
      background-color: transparent;
      color: $active-color;
    }
  } // the button to toggle search
  .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: $navbar-height;
    color: $white;
    padding-top: 0;
    padding-bottom: 0;
    @include transition(color 0.3s ease);
    @include media-breakpoint-up(md) {
      color: $nav-top-item-desktop;
    }
  }

  @include media-breakpoint-down(sm) {
    .navbar-text {
      margin: 10px;
    }
  }

  #header-register-button {
    position: relative;
    display: flex;

    a {
      background: rgba($adscore-good, 0.9);
      color: $whiteAlpha90;
      text-shadow: 0 0 1px $adscore-secondary-darkest;
      box-shadow: 0 1px 5px $adscore-primary-darkest;
      border: 1px solid $whiteAlpha25;
      border-radius: 3px;
      font-family: $base-font-family;
      font-size: 0.9rem;
      font-weight: 800;
      line-height: 1;
      letter-spacing: 1px;
      margin-left: 2.5rem;
      margin-right: 1.25rem;
      padding: 0.75rem 1.25rem 0.7rem 1.25rem;
      display: block;
      align-self: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.15s ease, box-shadow 0.15s ease, background-color 0.15s ease, text-shadow 0.15s ease;

      &:hover {
        color: $white;
        background-color: $adscore-good;
        box-shadow: 0 1px 1px $adscore-primary-darkest;
      }
    }
  }
}

.aside-collapsed .demo-badge {
  left: 0.87rem !important;
  bottom: -0.2rem !important;
}

// Customize header for horizontal layout
.layout-h {
  .topnavbar {
    // flex-flow: wrap;
    .navbar-header {
      display: flex;
      width: 100%;
      padding: 0;
      text-align: left;
      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
    .navbar-toggler {
      border: 0;
      margin-left: auto;
      .navbar-toggler-icon {
        $button-toggler-icon-color: $whiteAlpha75;
        background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='#{$button-toggler-icon-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
      }
    }
    // make menu full size
    .dropdown-menu {
      @include media-breakpoint-down(md) {
        left: 0 !important;
        right: 0 !important;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .sidebar-toggle {
    position: absolute !important;
    top: 2px;
    left: 0;
    z-index: 3001; // Add color only for the icon
    > em {
      color: $white;
    }
  }
}

// Navbar Search Form
// ------------------------------
.topnavbar {
  .navbar-form {
    position: absolute;
    top: -100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    height: $navbar-height;
    z-index: 9001;
    transition: all 0.3s;
    border: 0;
    border-bottom: 1px solid $gainsboro;

    .form-group {
      height: 100%;
      width: 100%;
    }

    .form-control {
      height: 100%;
      border: 0;
      border-radius: 0;
      width: 100%;
    }
    &.open {
      top: 0;
    } // re locate and center feedback
    .navbar-form-close {
      position: absolute;
      height: 30px;
      cursor: pointer;
      top: 50%;
      right: 0;
      margin-top: -15px;
      line-height: 30px;
      margin-right: 10px;
      color: $grayMist;
      font-size: 1.5em;
      pointer-events: auto; // make icon clickable
    }
  }

  @include media-breakpoint-up(md) {
    .navbar-form {
      left: $nav-header-wd;
    }
  }
}

// Navbar Desktop styles
// ------------------------------
@include media-breakpoint-up(md) {
  // Navbar top styles
  .topnavbar {
    .navbar-header {
      @include gradient-remove();
    } // Dropdown link open style
    .navbar-nav > a {
      color: $active-color;
      box-shadow: 0 0 0 $white inset;
      @include transition(all 0.2s);
    }

    .navbar-nav > .nav-item.show > .nav-link {
      &,
      &:hover,
      &:focus {
        box-shadow: 0 -2px 0 $white inset;
      }
    }
    // relocate search toggle button
    .navbar-nav > li > [data-toggle='navbar-search'] {
      position: static;
    }
  } // .topnavbar
}

@include media-breakpoint-up(md) {
  .aside-collapsed {
    .topnavbar {
      .navbar-header {
        .brand-logo {
          display: none;
        }
        .brand-logo-collapsed {
          display: block;
        }
      }

      .navbar-header {
        width: $nav-header-wd-toggled;
      }

      .navbar-form {
        left: $nav-header-wd-toggled;
      }
    }
  }
}

// Header on aside collapsed with Text
@include media-breakpoint-up(md) {
  .aside-collapsed-text {
    .topnavbar {
      .navbar-header {
        .brand-logo {
          display: none;
        }
        .brand-logo-collapsed {
          display: block;
        }
      }

      .navbar-header {
        width: $nav-header-wd-toggled-text;
      }

      .navbar-form {
        left: $nav-header-wd-toggled-text;
      }
    }
  }
}
