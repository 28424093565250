/* ========================================================================
     Component:inputs
 ========================================================================== */

/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Note editor
 */

//
// CHECKBOX AND RADIOS
//
@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints';

$ui-fg-color: $primary;
$ui-bg-color: $lightGray2;

@mixin switch-variant($wd, $hg) {
  & span {
    width: $wd;
    height: $hg;
  }

  & span:after {
    height: $hg - 2;
    width: $hg - 2;
  }
}

.c-checkbox {
  margin-right: 4px;
  * {
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
  } // the element that replaces the input
  fa-icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top; // margin-left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid $adscore-border-color;
    background-color: $adscore-border-color;
    color: $adscore-primary;
    margin-right: 5px;
    text-align: center;
    border-radius: 0;
    &:before {
      margin-left: 1px; // fa align trick
    }
    &.hidden {
      svg {
        display: none;
      }
    }
  }

  .form-inline & span {
    margin-left: 0;
  }

  &.c-checkbox-rounded {
    span {
      border-radius: 500px;
    }
  }
}

/* the icon */
.c-checkbox {
  span:before {
    //content: "\2713"; /* comment content to use custom icon classes */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
  }
}

/* Checked state */
.c-checkbox {
  input[type='checkbox']:checked + span:before,
  input[type='radio']:checked + span:before {
    color: $adscore-primary;
    opacity: 1;
  }

  /* override for radio */
  input[type='radio']:checked + span {
    background-color: $white;
    &:before {
      color: $ui-fg-color;
    }
  }

  /* Disable state */
  input[type='checkbox']:disabled + span,
  input[type='radio']:disabled + span {
    border-color: $ui-bg-color !important;
    background-color: $ui-bg-color !important;
  }
  /* override for radio */
  input[type='radio']:disabled + span {
    background-color: $white !important;
    &:before {
      color: $ui-bg-color;
    }
  }
}

//
// Form SWITCH
// Supports radio and checkbox
//

$switch-wd: 40px;
$switch-hg: $switch-wd * 0.5;

$switch-lg-wd: 50px;
$switch-lg-hg: $switch-lg-wd * 0.5;

$switch-sm-wd: 30px;
$switch-sm-hg: $switch-sm-wd * 0.5;

.switch {
  .form-control {
    padding-top: 7px;
    margin-bottom: 0;
  }
}

.switch * {
  cursor: pointer;
}

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.switch {
  display: flex;
  align-items: center;
  direction: row;
  & span {
    position: relative;
    display: inline-block;
    width: $switch-wd;
    height: $switch-hg;
    background-color: $content-bg;
    border: 2px solid $adscore-border-color;
    border-radius: 100px;
    transition: all 0.5s;
  }

  & span:after {
    content: '';
    position: absolute;
    background-color: $white;
    top: 0;
    left: -1px;
    height: $switch-hg - 4;
    width: $switch-hg - 4;
    border: 2px solid $adscore-border-color;
    border-radius: 400px;
    box-shadow: 1px 1px 3px $shadowLight;
    transition: all 0.2s;
  }

  &.switch-between {
    & span {
      background-color: $adscore-secondary-light;
    }

    & input:checked + span {
      background-color: $adscore-primary-light;
    }
  }

  &.switch-lg {
    & span:after {
      top: -1px;
      left: -1px;
    }

    @include switch-variant($switch-lg-wd, $switch-lg-hg);
  }
  &.switch-sm {
    & span:after {
      top: -1px;
      left: -1px;
    }

    @include switch-variant($switch-sm-wd, $switch-sm-hg);
  }
}

.switch input {
  &:checked + span {
    background-color: $ui-fg-color;
    transition: all 0.5s;
  }

  &:checked + span:after {
    left: calc(50% + 2px);
    transition: all 0.2s;
  }

  &:disabled + span {
    background-color: $veryLightGray;
    cursor: not-allowed;
  }
}

//
// Textarea note editor
//

.note-editor {
  background-image: linear-gradient($lightGray5 0.1em, transparent 0.1em);

  background-size: 100% 20px;
  background-color: $white;

  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none;
  $margin-color: $warning;
  &.note-editor-margin {
    background-image: linear-gradient(
        90deg,
        transparent 49px,
        lighten($margin-color, 30%) 49px,
        lighten($margin-color, 30%) 51px,
        transparent 51px
      ),
      linear-gradient($lightGray5 0.1em, transparent 0.1em);
    padding-left: 55px;
  }
}

// =========================================================================
// Adscore Input Styling
// - overrides to Angle and Bootstrap default styles
// - set globally, to be accessed site-wide by all instances of the inputs
// =========================================================================

.input-component-container {
  .input-field {
    @include media-breakpoint-up(sm) {
      &:not(.multiple) {
        &,
        > input {
          height: 50px;
        }
      }
    }
    background-color: transparent;
    position: relative;
    width: 100%;

    label,
    &.required:after,
    &.optional:after {
      font-size: 0.75rem;
      line-height: 1;
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      z-index: 5;
    }

    label {
      font-weight: 700;
      letter-spacing: 0.02rem;
      left: 0.55rem;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-right: 1rem;
    }

    &.textarea {
      textarea + label {
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        padding: 0.5rem 0.5rem 0.25rem;
        transition: background-color 0.25s ease;
        background-color: rgba(mix($adscore-neutral-lightest, $black, 95%), 0.9);
      }

      textarea:not([readonly]):hover,
      textarea:not([readonly]):focus,
      textarea:not([readonly]):hover + label,
      textarea:not([readonly]):focus + label {
        background-color: $whiteAlpha90;
      }

      &.white-background textarea + label {
        background-color: $whiteAlpha90;
      }
    }

    &.required:after,
    &.optional:after {
      right: 0.5rem;
      z-index: 0;
    }

    &.required:after {
      content: '*';
      color: $adscore-primary;
      font-weight: 900;
      z-index: 5;
    }

    .ngx-select.dropdown .ngx-select__toggle,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    textarea {
      background-color: mix($adscore-neutral-lightest, $black, 95%);
      border: 1px solid $adscore-neutral-lighter;
      border-radius: 0;
      box-shadow: none;
      color: $adscore-primary;
      font-size: 0.9rem;
      font-weight: 800;

      line-height: 1rem;
      outline: 0 !important;
      padding: 1.5rem 0.5rem 0.5rem;
      @include media-breakpoint-down(sm) {
        // On mobile version, input label sometimes takes 2 lines, so we need more padding.
        padding-top: 2rem;
      }
      width: 100%;
      -webkit-appearance: none;
      appearance: none;
      transition: background-color 0.25s ease;

      // Chrome/Opera/Safari
      &::-webkit-input-placeholder {
        color: $adscore-secondary-lighter;
      }
    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    textarea {
      &:not(:read-only):hover,
      &:not(:read-only):focus {
        background-color: $whiteAlpha90;
      }

      &:read-only {
        color: $adscore-secondary-lighter !important;
        cursor: not-allowed !important;
      }
    }

    .ngx-select.dropdown .ngx-select__disabled + .ngx-select__selected .ngx-select__toggle {
      color: $adscore-secondary-lighter !important;
      cursor: not-allowed !important;
    }

    .ngx-select.dropdown div:not(.ngx-select__disabled) + .ngx-select__selected .ngx-select__toggle {
      &:hover,
      &:focus {
        background-color: $white;
      }
    }

    input[type='password'] {
      letter-spacing: 0.1rem;
    }

    ngx-select {
      position: relative;
    }

    ngx-select.not-valid .ngx-select.dropdown .ngx-select__selected .ngx-select__toggle,
    ngx-select.not-valid .ngx-select.dropdown {
      border-color: $adscore-primary !important;
    }

    .use-space .ngx-select__choices.dropdown-menu {
      position: relative;
    }

    .ngx-select {
      .ngx-select__toggle .dropdown-toggle::after {
        color: $adscore-neutral;
      }

      .ngx-select__disabled {
        opacity: 0;
      }

      i.up,
      i.down {
        font-family: 'FontAwesome';
        font-size: inherit;
        margin-right: 5px;
        text-rendering: auto;
        font-style: normal;
        font-weight: normal;

        &.up:before {
          content: '\f062';
        }

        &.down:before {
          content: '\f063';
        }
      }

      .ngx-select__choices.dropdown-menu {
        border-radius: 0;
        padding: 0;
        border-color: $adscore-neutral-lighter;
        border-top: 0;

        .ngx-select__item {
          padding: 0.5rem;
          font-size: 0.75rem;
          font-weight: 700;
          line-height: 1;

          &.ngx-select__item_active.active {
            background-color: $adscore-secondary-light;
          }
        }

        .ngx-select__item.ngx-select__item_no-found {
          color: $adscore-neutral-darker;
          font-weight: 800;
        }
      }
    }

    .text-secondary .ngx-select .ngx-select__toggle {
      color: $adscore-secondary;
    }
    .background-white .ngx-select .ngx-select__toggle {
      background-color: $white;
    }

    &.multiple {
      .ngx-select .ngx-select__selected {
        padding: 1.5rem 0.5rem 0 !important;
      }

      &.multiple .ngx-select .ngx-select__search {
        background-color: mix($adscore-neutral-lightest, $black, 95%);
        border: 0;
        border-radius: 0;
        box-shadow: none;
        color: $adscore-primary;
        font-size: 0.9rem;
        font-weight: 800;
        line-height: 1rem;
        outline: 0 !important;
        padding: 0 0.5rem 0.5rem !important;
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        transition: background-color 0.25s ease;

        &:hover,
        &:focus {
          background-color: $white;
        }
      }

      &.multiple .ngx-select {
        border-radius: 0;
        border: 0;
        background-color: mix($adscore-neutral-lightest, $black, 95%);
        border: 1px solid $adscore-neutral-lighter;
        border-radius: 0;
        box-shadow: none;
        color: $adscore-primary;
        font-size: 0.9rem;
        font-weight: 800;
        line-height: 1rem;
        outline: 0 !important;
        padding: 1.5rem 0.5rem 0.5rem;
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        transition: background-color 0.25s ease;

        &:hover,
        &:focus {
          background-color: $white;
        }

        .ngx-select__toggle .dropdown-toggle::after {
          color: $adscore-neutral;
        }

        // X to remove the item
        .ngx-select__clear {
          color: $adscore-secondary;
        }

        .ngx-select__selected-plural.ui-select-match-item {
          border-radius: 0;
          border: 0;
          background-color: $shadowLight;
          color: $adscore-secondary;
        }

        .ngx-select__selected-plural.btn-secondary:hover {
          color: $darkGray;
        }

        .ngx-select__selected-plural.btn {
          margin: 0.1rem 0.25rem 0.25rem 0;
        }

        .ngx-select__choices.dropdown-menu {
          border-radius: 0;
          padding: 0;
          border-color: $adscore-neutral-lighter;
          border-top: 0;

          .ngx-select__item {
            padding: 0.5rem;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1;

            &.ngx-select__item_active.active {
              background-color: $adscore-secondary-light;
            }
          }

          .ngx-select__item.ngx-select__item_no-found {
            color: $adscore-neutral-darker;
            font-weight: 800;
          }
        }
      }
    }
  }
}

// https://blog.maximerouiller.com/post/remove-the-x-from-internet-explorer-and-chrome-input-type-search/
/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// Style radio input
input[type='radio'] {
  position: relative;
  top: -1px;
  height: 20px;
  width: 20px;
  background: url('/assets/img/svg/radio_unchecked.svg');
  background-size: 20px;
  border: 0;
  border-radius: 50%;
  appearance: none;
  margin-right: 8px;
  &:checked {
    background: url('/assets/img/svg/radio_checked.svg');
    background-size: 20px;
  }
}

textarea {
  resize: none;
}
