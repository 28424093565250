// =========================================================================
// Fonts
// =========================================================================

// Kappa Display
// - used for main content / main headings / navigation
@font-face {
  font-family: 'kappa';
  src: url('/assets/fonts/kappadisplay-black-adscore.woff2') format('woff2'),
    url('/assets/fonts/kappadisplay-black-adscore.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'kappa';
  src: url('/assets/fonts/kappadisplay-bold-adscore.woff2') format('woff2'),
    url('/assets/fonts/kappadisplay-bold-adscore.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'kappa';
  src: url('/assets/fonts/kappadisplay-extrabold-adscore.woff2') format('woff2'),
    url('/assets/fonts/kappadisplay-extrabold-adscore.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'kappa';
  src: url('/assets/fonts/kappadisplay-regular-adscore.woff2') format('woff2'),
    url('/assets/fonts/kappadisplay-regular-adscore.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Town
// - used for the Adscore brand text and other places where we need to make
//   a connection with the brand
@font-face {
  font-family: 'town';
  src: url('/assets/fonts/town10display-black-adscore.woff2') format('woff2'),
    url('/assets/fonts/town10display-black-adscore.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'town';
  src: url('/assets/fonts/town10display-bold-adscore.woff2') format('woff2'),
    url('/assets/fonts/town10display-bold-adscore.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'town';
  src: url('/assets/fonts/town10display-regular-adscore.woff2') format('woff2'),
    url('/assets/fonts/town10display-regular-adscore.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Fira Code
// - used for places where we need monospaced fonts (code blocks)
@font-face {
  font-family: 'fira';
  src: url('/assets/fonts/FiraCode-Medium.woff2') format('woff2'),
    url('/assets/fonts/FiraCode-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// This is an alternative to the Consolas font, which requires a licence
@font-face {
  font-family: 'Inconsolata';
  src: url('/adscore-landing-assets/Inconsolata-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
