@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';

.swal2-container .swal2-popup {
  padding: $adscore-spacing;
  width: 478px;
  .swal2-icon {
    &.swal2-error {
      border-color: $adscore-primary;
      [class^='swal2-x-mark-line'] {
        background-color: $adscore-primary;
      }
    }

    &.swal2-success {
      .swal2-success-ring {
        border-color: rgba($adscore-good, 0.25);
      }
      [class^='swal2-success-line'] {
        background-color: $adscore-good;
      }
    }

    &.swal2-info {
      border-color: $skyMist;
      color: $skyMist;
    }

    &.sa-info::after {
      left: 50%;
    }
  }

  p {
    color: inherit;
    font-weight: 400;
    text-align: left;
  }

  h2 {
    color: $adscore-secondary-light;
  }

  .sa-custom-content {
    font-family: $base-font-family;
    font-size: 0.9rem;
    padding: 1.25rem;
    color: $text-color;
    margin: var(--modal-padding-smaller) 0 0;
    border-top: 1px solid rgba($adscore-secondary-lightest, 0.5);
    border-bottom: 1px solid rgba($adscore-secondary-lightest, 0.5);
    text-align: center;

    ul {
      &:not(.bullets) {
        list-style-type: none;
        padding-left: 0;
      }

      &.bullets {
        padding-left: 1.25rem;
      }

      margin: 1rem 0;

      li + li {
        margin: 0.5rem 0 0;
        padding: 0;
      }

      li.error {
        color: $adscore-primary;
        text-align: left;
      }
    }

    input {
      height: auto;
      margin-top: 10px;
      margin-bottom: 17px;
    }
  }

  fieldset {
    display: none !important;
  }

  .swal2-actions {
    margin-left: -($adscore-spacing * 0.5);
    margin-right: -($adscore-spacing * 0.5);
    justify-content: space-around;
    width: auto;
    button {
      border-radius: 0;
      box-shadow: none !important;
      border: 0 !important;
      outline: 0 !important;
      font-weight: 700;
      margin: $adscore-spacing ($adscore-spacing * 0.5) 0;
      transition: opacity 0.25s ease;
      opacity: 1;

      &.swal2-cancel {
        background-color: $adscore-secondary-lighter;
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .swal2-footer {
    border-top: 1px solid rgba($adscore-secondary-lightest, 0.5);
  }

  .swal2-styled {
    padding: {
      left: 2em;
      right: 2em;
    }
  }

  #swal2-html-container {
    margin: 0;
  }
}

html body.swal2-height-auto {
  height: 100% !important;
}

.swal2-icon.no-border {
  border: none;
}
