// =========================================================================
// ! Homepage - Core Styles // =========================================================================

@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints';
$enable-transitions: true;
@import 'bootstrap/scss/mixins/_transition.scss';
@import 'color-variables.scss';

// ! Page Setup
// =========================================================================

#loading-indicator {
  background: $whiteAlpha85;
  position: fixed;
  overflow: hidden;
  z-index: 10;
  top: -10%;
  right: -10%;
  bottom: -10%;
  left: -10%;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      width: 150px;
      height: 140px;
      margin: auto;
      overflow: auto;
      background-size: 150px 140px;
    }

    #balls {
      position: relative;
      text-align: center;
      margin-top: 1.5rem;
    }
  }
}

.fake-loader {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: -9999999;
  background-color: transparent;
}

$fadeDuration: 300ms;

.fade-out-loader {
  z-index: 9999999;
  background-color: transparent;
  transition: background-color $fadeDuration linear;
}

.fade-loader {
  z-index: 9999999;
  background-color: $whiteAlpha25;
  transition: background-color $fadeDuration linear;
}

#balls {
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
}

#page,
#main {
  position: relative;
  width: 100%;
}

#page {
  min-height: 100vh;
  height: auto;
}

#main {
  max-width: 1340px;
  margin: 0 auto;
  min-height: 100%;
  height: auto;

  background-color: $white;
  background-repeat: no-repeat;
  background-size: auto 426px;
  background-position: right -1px;

  @include media-breakpoint-down(sm) {
    background-image: none;
  }
  &.logged-in {
    #landing-screen {
      @include media-breakpoint-down(sm) {
        padding-top: 10rem;
      }
    }
  }
}

.section {
  width: 100%;
  position: relative;

  &.section--spread {
    min-height: 100vh;
  }
}

.section--container {
  padding: 4rem;

  @include media-breakpoint-down(sm) {
    padding: 5vw;
  }

  @media (min-width: 1200px) and (max-height: 800px) {
    padding: 3rem 4rem;
  }
}

// ! Homepage Navigation Bar
// =========================================================================
#homepage-header,
#home-sidebar-wrapper,
#homepage-footer {
  nav {
    &#additional-resources {
      @include media-breakpoint-up(sm) {
        margin-top: 1rem;
      }
    }
    .nav-bar {
      list-style-type: none;
    }

    a,
    button {
      color: $white;
      display: block;
      font-weight: 700;
      line-height: 1;
      padding: $adscore-spacing 0;
      text-decoration: none;
      text-transform: uppercase;
      transition: box-shadow 0.25s ease-in-out;
    }

    button {
      background-color: transparent;
      border: none;
    }

    li {
      display: inline-block;
    }

    @include media-breakpoint-down(sm) {
      li {
        padding-left: calc(0.5rem - 4px);
        padding-right: calc(0.5rem - 4px);

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }

      a {
        padding: 0.75rem 0;
      }
    }
  }
}

// ! Header Area
// =========================================================================

#homepage-header {
  @include media-breakpoint-down(sm) {
    #toggle-and-logo {
      position: fixed;
      left: 0;
      width: 100%;
      background: $adscore-secondary;
      z-index: 200;
      height: 4rem;
    }
    // Three dots to toggle side menu
    nav {
      width: initial !important;
      position: absolute !important;
      left: calc(1.25rem + 2px);
    }
    app-navigation-bar {
      display: none;
    }
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;

  nav {
    width: 100%;
    padding-right: 3rem;
    position: relative;

    li {
      & + li {
        margin-left: calc(1.5rem - 4px);
      }

      @include media-breakpoint-up(md) {
        div:not(.sublink, .sublinks) {
          border-top: 3px solid transparent !important;
        }
        div:not(.sublink, .sublinks):hover,
        div.open.sublinks-container {
          border-top: 3px solid rgba($white, 0.75) !important;
          transform: none;
          a {
            transform: translateY(-3px);
          }
        }
      }

      div:not(.sublink, .sublinks) {
        transform: translateY(-3px);
        border-top: 3px solid transparent !important;
      }

      &.active {
        div:not(.sublink, .sublinks) {
          border-top: 3px solid $white !important;
          transform: none;
        }
        a {
          transform: translateY(-3px);
        }
      }
    }

    #language {
      position: absolute;
      right: 0;
      top: 0;
    }

    @include media-breakpoint-down(sm) {
      padding-right: 0;
      text-align: center;

      #language {
        top: 2.25rem;
        right: 1.25rem;
        z-index: 1;
      }
    }
  }

  #top-wing--for-mobile {
    display: none;
  }

  #header--second-level {
    margin-left: 2rem;
    margin-top: 21px;
  }

  #header--identity {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    .logo {
      width: 8.5rem;
      height: 8.5rem;
      position: relative;

      background-image: url('/assets/img/adscore--logo--w-color--wo-text.min.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
    }

    // offset logo text to align w/ main landing screen heading
    .text-brand {
      margin-left: calc(0.5rem - 4px);
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  #header--identity-mobile {
    display: none;
    img {
      width: 48px;
    }
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
    }
  }

  #header--login {
    @include media-breakpoint-down(sm) {
      padding-top: 4rem;
      &:not(.logged-in) {
        display: none;
      }
    }
    width: 22rem;
    max-width: 100%;
    float: right;
    position: relative;

    form:after,
    .logged-in-controls:after {
      // button padding top + button padding bottom + font-size
      $login-button-separator-size: calc(1rem + 1rem + 0.875rem);

      background-color: $adscore-secondary;
      border-radius: 50%;
      color: $white;
      content: 'or';
      font-size: 1rem;
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      width: $login-button-separator-size;
      height: $login-button-separator-size;
      line-height: $login-button-separator-size;
      transform: translate(-50%, 0);
    }

    form:after {
      bottom: 3.25rem;
    }

    .logged-in-controls:after {
      bottom: 0;
    }

    #remember-me {
      height: 2rem;
      color: $gray;
    }

    .button {
      padding-left: 1.1rem;
      padding-right: 1.1rem;
    }

    &.logged-in {
      @media (max-width: 480px) {
        margin-bottom: 2rem;
      }
    }
  }

  @media (max-width: 1340px) {
    & {
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      width: calc(100% - 2.5rem);
    }
    #header--login {
      width: 20.75rem;
    }

    #header--identity {
      margin-left: -1rem;
    }
  }

  @media (max-width: 1090px) {
    #header--second-level {
      margin-left: -0.5rem;
    }

    #header--identity {
      margin-left: 1.25rem;

      .text-brand {
        width: 8.5rem;
        text-align: center;
        font-size: 1.5rem !important;
        margin-top: 0.75rem !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    background-color: $adscore-secondary;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100%;

    #top-wing--for-mobile {
      display: none;
      position: absolute;
      right: 0;
      overflow: hidden;

      svg {
        width: 55rem;
        height: auto;
        margin-top: -5px;
        margin-right: -4rem;
        path {
          fill: $adscore-secondary;
        }
      }
    }

    #header--second-level {
      margin-top: 0;
    }

    #header--identity {
      position: absolute;
      top: 1.25rem;

      .logo {
        width: 6.5rem;
        height: 6.5rem;
      }

      .text-brand {
        width: 6.5rem;
        margin-top: 0.5rem !important;
        font-size: 1.25rem !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    #header--identity {
      margin-left: 2.5rem;
      top: 2rem;
    }

    #top-wing--for-mobile {
      svg {
        width: 28rem;
        margin-top: -2px;
        margin-right: -2rem;
      }
    }
  }
}

// ! Footer Area
// =========================================================================
#homepage-footer {
  margin-top: -4rem;

  nav li {
    margin: 0 0.35rem;

    @include media-breakpoint-up(md) {
      div:not(.sublink, .sublinks) {
        border-bottom: 3px solid transparent;
      }
      div:not(.sublink, .sublinks):hover,
      div.open.sublinks-container {
        border-bottom: 3px solid rgba($white, 0.75);
        transform: none;
        a,
        button {
          transform: translateY(3px);
        }
      }
    }

    div:not(.sublink, .sublinks) {
      transform: translateY(3px);
      border-bottom: 3px solid transparent;
    }

    &.active {
      div:not(.sublink, .sublinks) {
        border-bottom: 3px solid $white;
        transform: none;
      }
      a {
        transform: translateY(3px);
      }
    }
  }
}

.footer--wing {
  background-color: transparent;
  position: relative;

  .wing {
    margin-left: -2px;
    margin-right: -2px;
  }

  .wing,
  .logo {
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .logo {
    width: 4rem;
    height: auto;
    position: absolute;
    left: 50%;
    bottom: 20%;
    transform: translate(-50%, 0);
  }
}

@include media-breakpoint-down(sm) {
  #homepage-footer {
    margin-top: 0;
  }

  .footer--wing {
    .wing {
      padding-top: 1.8rem;
    }

    .logo {
      bottom: 0;
      height: 3rem;

      svg {
        height: 100%;
        width: auto;
        margin: 0 auto;
      }
    }
  }
}

// ! Button Definitions
// =========================================================================

.button {
  background: $adscore-secondary;
  color: $white;
  text-decoration: none !important;
  display: inline-block;
  font-weight: 900;
  letter-spacing: 0.02rem;
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 1;
  padding: 1rem 2rem;
  transition: opacity 0.25s ease;
  -webkit-tap-highlight-color: transparent;
  border: 0;

  &.cta {
    font-size: 1.25rem;
    padding: 1.25rem 2.25rem;

    &:after {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 1.25rem;
      bottom: -1.1rem;
      right: -0.5rem;

      background-image: url('/assets/img/adscore--cta--bottom-wing.min.svg');
      background-repeat: no-repeat;
      background-position: right top;
      background-size: contain;
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      opacity: 0.9;
    }
  }
}

// ! Landing Section
// =========================================================================

#landing-screen {
  padding-top: 15rem;
  z-index: 1;

  .section--container {
    padding-left: 11rem;

    @media (min-width: 1090px) and (max-width: 1600px) {
      padding-top: 1rem;
      padding-bottom: 3rem;
    }
  }

  @include media-breakpoint-down(lg) {
    .section--container {
      padding-left: 5vw;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: 4rem;

    .section--container h1 {
      font-size: 1.75rem;

      + p {
        font-size: 1rem !important;
      }
    }
  }

  @media (min-width: 1200px) and (max-height: 800px) {
    .section--container {
      padding-top: 0;
    }
  }
}

// ! Homepage Form Elements
// =========================================================================
.textarea-wrapper {
  height: 10rem;
}

// ! Hompage Marketing Pages
// =========================================================================

.marketing-landing-page {
  section + section .section--container {
    padding-top: 2rem;
  }

  app-content-divider + section .section--container {
    padding-bottom: 2.5rem;
  }

  section + app-cta {
    display: block;
    margin-top: -2rem;
  }
}

#wings-top {
  position: absolute;
  top: -2px;
  pointer-events: none;
  z-index: 1;
  left: -2px;
  width: calc(100% + 4px);
  max-width: calc(100% + 4px);
}

#header--sidebar {
  width: 12%;
  height: 100%;
  position: absolute;
  top: 75px;
  left: 63px;
  z-index: 0;
}

#page[is-faq] {
  #homepage-header {
    pointer-events: none;
    #header--login,
    nav {
      pointer-events: initial;
    }
  }
}

@include media-breakpoint-down(sm) {
  #wings-top {
    display: none !important;
  }
}

#home-sidebar-overlay {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  background: $text-alpha-inverse;
  position: fixed;
  top: 4rem;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}

#home-sidebar-wrapper {
  position: fixed;
  left: 0;
  z-index: 10;
  width: 13.75rem;
  margin-left: -13.75rem;
  top: 4rem;
  background: $adscore-secondary;
  height: 100%;
  border-top: 1px solid $black;
  @include transition(#{'margin-left .2s cubic-bezier(0.35, 0, 0.25, 1)'});
  li {
    width: 100%;
    padding: {
      left: 1.25rem !important;
      right: 1.25rem !important;
    }
    &.active {
      border-left: 3px solid $white;
      a {
        margin-left: -3px;
      }
    }
  }
  hr {
    margin: {
      left: 1.25rem;
      right: 1.25rem;
    }
    border-top: 1px solid $white;
    opacity: 1;
  }
}

.aside-toggled-mobile {
  #home-sidebar-overlay {
    opacity: 1;
    pointer-events: initial;
  }
  #home-sidebar-wrapper {
    margin-left: 0;
  }
}

header .text-brand {
  @include media-breakpoint-down(sm) {
    color: $white;
  }
}

.footer--content {
  // Hack to avoid horizontal line
  margin-top: -1px;
  padding-top: 13px;
}

#badge-img {
  width: 160px;
  margin-right: 12px;
  @include media-breakpoint-down(sm) {
    width: 100px;
    margin: 25px auto;
  }
}
