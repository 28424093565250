/*!
 *
 * Angle - Bootstrap Admin App + AngularJS
 *
 * Version: 3.8.8
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import 'bootstrap-classes/bs-variable';
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-classes/bootstrap-override.scss';

// Variables
@import 'app/fonts';
@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';
@import 'app/functional';
@import 'app/typography';
// Layout
@import 'app/layout';
@import 'app/layout2';
@import 'app/layout-extra';
@import 'app/layout-animation';
@import 'app/top-navbar';

// Common
@import 'app/bootstrap-reset';
@import 'app/bootstrap-custom';
@import 'app/button-extra';
@import 'app/dropdown-extra';
@import 'app/animate';
@import 'app/inputs';
@import 'app/utils';
// Elements
@import 'app/spinner';
// Form elements
@import 'app/form-select2';
// Extras
@import 'app/adscore';
// Material Angular override
@import 'app/mat-tooltip.scss';

// Theme style
@import 'app/__COPY_theme-d.scss';

// Prism code highlighting
@import 'app/prism-adscore.scss';

@import '@adscore/adscore-frontend-common/src/lib/styles/toaster.scss';
@import 'app/sweet-alert';
@import 'app/modals';

fa-icon svg {
  height: 1em;
  vertical-align: -0.125em;
}
