/**
 * prism.js - Adscore Flavor
 * Based on Coy theme for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/tshedor/workshop-wp-theme (Example: http://workshop.kansan.com/category/sessions/basics or http://workshop.timshedor.com/category/sessions/basics);
 * @author Tim  Shedor
 */

@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';

code[class*='language-'],
pre[class*='language-'] {
  color: $text-color;
  background: none;
  font-family: 'fira', Consolas, Monaco, monospace;
  font-weight: 400;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.8;

  tab-size: 4;

  -webkit-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*='language-'] {
  position: relative;
  margin: 0.5em 0;
  overflow: visible;
  padding: 0;
}
pre[class*='language-'] > code {
  position: relative;
  background-color: $adscore-neutral-lightest;
  border-radius: 3px;
  padding: 1rem;
}

code[class*='language'] {
  max-height: inherit;
  padding: 0 1rem;
  display: block;
  overflow: auto;
}

/* Margin bottom to accomodate shadow */
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background-color: $adscore-neutral-lightest;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  position: relative;
  padding: 0.25rem;
  border-radius: 3px;
  color: $adscore-primary;
  border: 1px solid $adscore-neutral-lighter;
  display: inline;
  white-space: normal;
}

pre[class*='language-']:before,
pre[class*='language-']:after {
  content: '';
  z-index: -2;
  display: none;
  position: absolute;
  bottom: 0.75em;
  left: 0.18em;
  width: 40%;
  height: 20%;
  max-height: 13em;
  box-shadow: 0px 13px 8px $lightSlateGray3;
  transform: rotate(-2deg);
}

:not(pre) > code[class*='language-']:after,
pre[class*='language-']:after {
  right: 0.75em;
  left: auto;
  transform: rotate(2deg);
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: $adscore-neutral-dark;
}

.token.punctuation {
  color: $adscore-neutral-darker;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.function-name,
.token.constant,
.token.symbol,
.token.deleted {
  color: $adscore-primary;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.function,
.token.builtin,
.token.inserted {
  color: mix($adscore-good, $black, 85%);
}

.token.operator,
.token.entity,
.token.url,
.token.variable {
  color: $adscore-primary-light;
  background: $whiteAlpha50;
}

.token.atrule,
.token.attr-value,
.token.keyword,
.token.class-name {
  color: $adscore-purple;
}

.token.regex,
.token.important {
  color: $adscore-purple;
}

.language-css .token.string,
.style .token.string {
  color: $adscore-primary-light;
  background: $whiteAlpha50;
}

.token.important {
  font-weight: normal;
}

.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.namespace {
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  pre[class*='language-']:before,
  pre[class*='language-']:after {
    bottom: 14px;
    box-shadow: none;
  }
}

/* Plugin styles */
.token.tab:not(:empty):before,
.token.cr:before,
.token.lf:before {
  color: $cream;
}

/* Plugin styles: Line Numbers */
pre[class*='language-'].line-numbers {
  padding-left: 0;
}

pre[class*='language-'].line-numbers code {
  padding-left: 3.8em;
}

pre[class*='language-'].line-numbers .line-numbers-rows {
  left: 0;
}

/* Plugin styles: Line Highlight */
pre[class*='language-'][data-line] {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
pre[data-line] code {
  position: relative;
  padding-left: 4em;
}
pre .line-highlight {
  margin-top: 0;
}

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre.line-numbers > code {
  position: relative;
  white-space: inherit;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 1rem; // compensate for code's padding
  font-size: 100%;
  left: -3.8em;
  width: 3em; // works for line-numbers below 1000 lines -- more needed after that
  letter-spacing: -1px;
  user-select: none;
}

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: $adscore-neutral;
  display: block;
  padding-right: 0.8em;
  text-align: right;
}
