// =========================================================================
// Adscore Specific common helpers
// - common CSS styles & overrides tailored for the Adscore project
// - useful for both Homepage and Dashboard
// =========================================================================

// Site-wide color profile for text selection
// - visible when selecting text w/ mouse
*::selection {
  background: rgba($adscore-primary-light, 0.9);
  color: $white;
}

// Brand Text
// - usage: `<span class="brand"><span>AD</span>score</span>`
.text-brand {
  color: $adscore-secondary;
  font-family: 'town';
  font-weight: 400;
  text-transform: uppercase;

  span {
    font-weight: 900;
  }
}

// Big Dots
// - usage: `32<span class="decimal-separator">.</span>93`
.decimal-separator {
  font-weight: 900;
  line-height: 0;
  font-size: 1em;
  padding-left: 1px;
  padding-right: 1px;
}

// Bootstrap Tooltip Overrides
// =========================================================================

.tooltip {
  font-family: $base-font-family;
  line-height: 1.45;

  // fixing choppy animation on displaying the tooltip
  // - ref: https://github.com/valor-software/ngx-bootstrap/issues/3075#issuecomment-363461060
  pointer-events: none;
}

// Badge Labels
// - colored pill shape for use w/ very short text labels
// - used to indicate status or highlight information (ex: "Past Due", "PayPal")
// - Bootstrap equivalent: https://getbootstrap.com/docs/4.1/components/badge/
// =========================================================================

.badge-label {
  color: $white;
  background-color: $adscore-secondary-light;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1;
  padding: 3px 4px 2px;
  border-radius: 2px;
  display: inline-block;
  vertical-align: text-top;
  text-transform: uppercase;

  &.badge-danger {
    color: $white;
    background-color: $adscore-primary;
  }

  &.badge-warning {
    color: $white;
    background-color: $adscore-yellow;
  }

  &.badge-success {
    color: $white;
    background-color: $adscore-good;
  }

  &.badge-neutral {
    background-color: $adscore-neutral-dark;
  }
}
