/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~simple-line-icons/css/simple-line-icons.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import '~spinkit/spinkit.css';
@import '~loaders.css/loaders.css';

//== Application
@import './app/shared/styles/app.scss';
