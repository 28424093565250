@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';
// Style for modals created with BsModalService

:root {
  --modal-padding-bigger: 2.5rem;
  --modal-padding-smaller: 2.5rem;
  @include media-breakpoint-down(sm) {
    --modal-padding-bigger: 2rem;
    --modal-padding-smaller: 1.5rem;
  }
}

.modal-open .modal {
  overflow-y: hidden;
  border-radius: 0.375rem;
}

bs-modal-backdrop + modal-container {
  div[role='document'].modal-dialog {
    margin: {
      top: 1rem;
      bottom: 1rem;
    }
    height: calc(100vh - 2rem);
    @include media-breakpoint-down(lg) {
      margin: 0;
      height: 100vh;
    }

    .modal-dialog {
      margin-top: 0;
    }
  }
}
