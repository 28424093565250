/* ========================================================================
   Component: preloader
 ========================================================================== */
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins';
@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';

$preloader-bg: $white;
$preloader-wd: 150px;
$preloader-hg: 140px;

/*@noflip*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: $preloader-bg;
  z-index: 9999999;
  height: 100%;
  width: 100%;
}

/*@noflip*/
.preloader-progress {
  position: relative;
  width: 100%;
  height: 100%;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ball-pulse div {
      background-color: $adscore-primary;
    }

    .logo {
      width: $preloader-wd;
      height: $preloader-hg;
      margin: auto;
      overflow: auto;
      background-image: url(../../../assets/img/preloader/adscore--preloader--full--secondary-lightest.min.svg);
      background-size: $preloader-wd $preloader-hg;
    }
  }
}

.preloader-hidden {
  display: none;
}
// ngAnimate behavior
.preloader-hidden-add {
  opacity: 1;
  display: block;

  .preloader-progress {
    transition: transform 0.1s ease;
    transform: scale(0);
  }
}
.preloader-hidden-add-active {
  opacity: 0;
}
