.cdk-overlay-container {
  z-index: 1110;
  .mdc-tooltip {
    &__surface {
      padding: 8px;
      color: $white;
      background-color: $adscore-secondary;
    }
  }

  .mat-mdc-tooltip-panel {
    .mat-mdc-tooltip::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &-above {
      .mat-mdc-tooltip::after {
        border-width: 5px 5px 0 5px;
        border-color: $adscore-secondary transparent transparent transparent;
        top: 100%;
        left: calc(50% - 5px);
      }
    }

    &-below {
      .mat-mdc-tooltip::after {
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent $adscore-secondary transparent;
        bottom: 100%;
        left: calc(50% - 5px);
      }
    }
    &-left {
      .mat-mdc-tooltip::after {
        border-width: 5px 5px 5px 0;
        border-color: transparent $adscore-secondary transparent transparent;
        right: 100%;
        top: calc(100% - 5px);
      }
    }
    &-right {
      .mat-mdc-tooltip::after {
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent $adscore-secondary;
        left: 100%;
        top: calc(100% - 5px);
      }
    }
  }
}

// mattooltip color
:root {
  --mdc-plain-tooltip-container-color: $adscoreAdminSecondary;
}
