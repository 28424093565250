@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins';
@import '@adscore/adscore-frontend-common/src/lib/styles/variables.scss';

.table {
  > :not(caption) > * > * {
    padding: 0;
  }

  > :not(:first-child) {
    border-top-color: $gray-200;
    border-top-width: 1px;
  }

  ::ng-deep span {
    padding: 0;
  }
}

a {
  color: $adscore-primary;
  text-decoration: none;
}
a:hover {
  color: $adscore-claret;
  text-decoration: underline;
}

//bootstrap/mixins/_text-emphasis.scss
@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%);
    }
  }
}

//bootstrap/_buttons.scss
.btn {
  padding: 0.375rem 1rem;
  @media (max-width: 360px) {
    padding: {
      left: 0.25rem;
      right: 0.25rem;
    }
  }
}

.button-copy {
  box-shadow: none;
  outline: 0;
  border: 0;
  height: 100%;
  color: $white;
  transition: background-color 0.25s ease;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  width: 6rem;
  color: $whiteAlpha75;
  background-color: rgba($adscore-secondary-light, 0.8);

  &:not([disabled]) {
    color: $white;
    background-color: $adscore-secondary-light;
    &:hover {
      background-color: $adscore-secondary;
    }
  }
}

button[disabled] {
  cursor: not-allowed !important;
}

//bootstrap/_forms.scss
.form-control {
  min-height: $input-line-height + 2 * $input-padding-y;
}

//bootstrap/_nav.scss
.navbar {
  height: 72px;
}

.navbar-brand {
  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }
}

// bootstrap/_tables.scss
.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);
  }
}

//bootstrap/mixins/_hover.scss
$jumbotron-padding: 2rem;
$jumbotron-bg: $gray-200;

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

small {
  font-weight: 400;
}
